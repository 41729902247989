@tailwind base;
@tailwind components;
@tailwind utilities;

.gray-scroll::-webkit-scrollbar {
  width: 0.2em;
  height: 0.3em;
}

.gray-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.gray-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.no_scroll-640::-webkit-scrollbar {
  display: auto;
}

@media screen and (max-width: 640px) {
  .no_scroll-640::-webkit-scrollbar {
    display: none;
  }
}

.react-datepicker__aria-live {
  display: none;
}

.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, currentColor 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

/* apple pay */
.applePay {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  /* Use any supported button type. */
  cursor: pointer;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

.applePayBlack {
  -apple-pay-button-style: black;
}

.applePayWhite {
  -apple-pay-button-style: white;
}

.product_description iframe {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .product_description iframe {
    width: 100%;
  }
}

#framePaymentPage {
  height: 100% !important;
  width: 100%;
}

summary {
  display: block;
  /* works in firefox */
  list-style: none;
  /* works in firefox */
}

summary::after {
  display: block;
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

@media (max-width: 1024px) {
  .react-select,
  .react-select__control,
  .react-select__value-container,
  .react-select__single-value,
  .react-select__multi-value,
  .react-select__multi-value__label,
  .react-select__multi-value__remove,
  .react-select__placeholder,
  .react-select__input,
  .react-select__menu,
  .react-select__menu-list,
  .react-select__option,
  .react-select__loading-indicator,
  .react-select__indicator-separator,
  .react-select__dropdown-indicator,
  .react-select__clear-indicator,
  .react-select__group,
  .react-select__group-heading,
  .react-select__indicators,
  .react-select__menu-notice {
    font-size: 13px !important;
    padding: 1.5px 0.5px !important;
  }
}
